import styled from 'styled-components';
import { Button } from '@material-ui/core';

const SecondaryButton = styled(Button).attrs(() => ({
  color: 'primary',
}))`
  && {
    margin: ${(props) => (props.nomargin ? '0rem' : '1rem')};
  }
`;

export default SecondaryButton;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { navigate } from 'gatsby';

import {
  Typography,
  Grid,
  Box,
  Container,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
} from '@material-ui/core';

import SecondaryButton from './SecondaryButton';
import StarRating from './StarRating';

const FeedbackCard = ({
  review: {
    review_feedback: reviewFeedback,
    customer_initials: customerInitials,
    review_timestamp: reviewTimestamp,
    review_stars: reviewStar,
    service: { title: serviceTitle },
  },
}) => (
  <Card style={{ margin: 10, width: 345 }}>
    <CardHeader
      avatar={
        // <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>
        <Avatar
          aria-label="recipe"
          style={{ backgroundColor: 'white', color: 'black' }}
        >
          {customerInitials}
        </Avatar>
      }
      title={serviceTitle}
      subheader={moment(reviewTimestamp).format('YYYY-MM-DD HH:mm')}
    />
    <CardContent>
      <Typography variant="body2" color="textPrimary" component="p">
        {reviewFeedback}
      </Typography>
    </CardContent>
    <CardActions>
      <StarRating rating={reviewStar} />
    </CardActions>
  </Card>
);

const Reviews = ({ shopSlug, barberSlug, reviews, showMore }) => {
  const { formatMessage: f } = useIntl();
  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom align="center">
          {f({ id: 'appNavAdminBookingReviews' })}
        </Typography>
        <Box
          display="flex"
          width="100%"
          flexWrap="wrap"
          justifyContent="center"
        >
          {reviews &&
            reviews.map((review) => (
              <FeedbackCard key={review.reviewId} review={review} />
            ))}
        </Box>
      </Container>
      {showMore ? (
        <Grid container justifyContent="center">
          <Box pt="2rem">
            <SecondaryButton
              variant="outlined"
              onClick={() =>
                navigate(`/shop/${shopSlug}/barbers/${barberSlug}/reviews`)
              }
            >
              {f({ id: 'bookingMoreReviews' })}
            </SecondaryButton>
          </Box>
        </Grid>
      ) : null}
    </>
  );
};

export default Reviews;

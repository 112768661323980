import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { Box, Grid, Container, Divider, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import SEO from './seo';

import Contact from './Contact';
import Reviews from './Reviews';

import OpeningHours from './OpeningHours';
import BookingFooter from './BookingFooter';
import SecondaryButton from './SecondaryButton';

const PaddedGrid = styled(Grid)`
  && {
    padding: 2rem 0rem;
  }
`;

const BarberReviews = ({
  shopDetails,
  barberDetails,
  barberNumPages,
  currentPage,
  reviews,
}) => {
  const { formatMessage: f } = useIntl();
  return (
    <>
      <SEO
        title={`${barberDetails.display_name} | ${f({
          id: 'appNavAdminBookingReviews',
        })}`}
        description={shopDetails.meta_description}
        thumbnailPreview={shopDetails.hero_image}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        minHeight="100vh"
      >
        <Box>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <Box
                textAlign="center"
                position="relative"
                mx="auto"
                pt={4}
                maxWidth="600px"
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom>
                      <Box fontWeight={600}>{barberDetails.display_name}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <PaddedGrid item xs={12}>
              <Reviews
                barberDetails={barberDetails}
                reviews={reviews.map((x) => x.node)}
                shopSlug={shopDetails.slug}
                barberSlug={barberDetails.slug}
              />
            </PaddedGrid>
          </Grid>
        </Box>
        <Box mb={2} alignSelf="center">
          <Pagination
            count={barberNumPages}
            onChange={(e, page) => {
              navigate(
                page === 1
                  ? `/shop/${shopDetails.slug}/barbers/${barberDetails.slug}/reviews/`
                  : `/shop/${shopDetails.slug}/barbers/${barberDetails.slug}/reviews/${page}`,
              );
            }}
            page={currentPage}
          />
        </Box>
        <Box mb={2} alignSelf="center">
          <SecondaryButton
            variant="outlined"
            onClick={() =>
              navigate(
                `/shop/${shopDetails.slug}/barbers/${barberDetails.slug}`,
              )
            }
          >
            {f({ id: 'barberReviewsBackToProfile' })}
          </SecondaryButton>
        </Box>
        <Box>
          <Divider />
          <Box mt={4}>
            <Container maxWidth="md">
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <Contact shopDetails={shopDetails} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OpeningHours openingHours={shopDetails.opening_hours} />
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box mt={2}>
            <BookingFooter />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BarberReviews;

import React from 'react';
import { IntlProvider } from 'react-intl';
import { graphql } from 'gatsby';

import { isSalonzone } from '../utils';
import messages from '../data/messages';
import salonzoneMessages from '../data/messages/salonzone';
import BarberReviews from '../components/BarberReviews';
import FontSelector from '../components/FontSelector';

export const reviewListQuery = graphql`
  query reviewListQuery($skip: Int!, $limit: Int!, $barberId: Int!) {
    allReview(
      limit: $limit
      skip: $skip
      filter: { employee_id: { eq: $barberId } }
    ) {
      edges {
        node {
          reviewId
          review_feedback
          review_stars
          review_timestamp
          customer_initials
          employee_id
          service_id
          shop_id
          service {
            title
          }
        }
      }
    }
  }
`;

const BarberReviewTemplate = ({
  pageContext: { shopDetails, barberDetails, barberNumPages, currentPage },
  data: {
    allReview: { edges: reviews },
  },
}) => {
  const locale =
    shopDetails && shopDetails.country && shopDetails.country.language_key;

  return (
    <IntlProvider
      locale={locale}
      messages={isSalonzone ? salonzoneMessages[locale] : messages[locale]}
    >
      <FontSelector>
        <BarberReviews
          shopDetails={shopDetails}
          barberDetails={barberDetails}
          barberNumPages={barberNumPages}
          currentPage={currentPage}
          reviews={reviews}
        />
      </FontSelector>
    </IntlProvider>
  );
};

export default BarberReviewTemplate;

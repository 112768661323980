import React from 'react';

import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';

export default ({ rating }) => (
  <>
    {[...Array(parseInt(rating, 10)).keys()].map((x) => (
      <StarRoundedIcon key={x} style={{ margin: 0, color: '#EBC63D' }} />
    ))}
    {!!(rating - parseInt(rating, 10)) ? (
      <StarHalfRoundedIcon style={{ margin: 0, color: '#EBC63D' }} />
    ) : null}
    {[
      ...Array(
        5 - parseInt(rating, 10) - (!!(rating - parseInt(rating, 10)) ? 1 : 0),
      ).keys(),
    ].map((x) => (
      <StarBorderRoundedIcon key={x} style={{ margin: 0, color: '#EBC63D' }} />
    ))}
  </>
);
